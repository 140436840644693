const initialState = {
	offers: {
		data: [],
		pagination: {
			count: 0,
			current_page: 0,
			per_page: 9,
			total: 0,
			total_pages: 0,
		},
	},
	listFilter: [],
	loadingOffers: true,
	stats: {},

	// Suggested AI candidates
	suggested_candidates: [],
	selectedSuggestedCandidate: null,
	loadingSuggestedCandidates: false,
	feedback: null,
	uuid: null,
	defaultStatusSlugs: ['active', 'pause', 'draft', 'pending', 'closed'],
}

export const useOffersStore = defineStore('offers', {
	state: () => ({ ...initialState }),
	actions: {
		async fetchFiltersOffers() {
			const { $repository } = useNuxtApp()
			const response = await $repository.v3.company.offers.getFiltersOffers()

			if (response.success) {
				this.listFilter = response.result
			}

			return response
		},
		async fetchOffers(params) {
			const { $repository } = useNuxtApp()

			this.loadingOffers = true
			const response = await $repository.v3.company.offers.getOffers({
				...params,
				page: params.page || 1,
				per_page: params.per_page || 9,
			})
			this.loadingOffers = false

			if (response.success) {
				this.offers = response.result
			}

			return response
		},
		async fetchStatsOffers() {
			const { $repository } = useNuxtApp()

			const response = await $repository.v3.company.offers.getDashboardCount()

			if (response.success) {
				this.stats = response.result
			}

			return response
		},
		async updateChangeOfferStatus(idOffer, params) {
			const { $repository } = useNuxtApp()
			const response = await $repository.v3.company.offers.putChangeOfferStatus(idOffer, params)

			if (response.success) {
				this.offers.data = this.offers.data.map((offer) => (offer.id === idOffer ? response.result : offer))
				await Promise.allSettled([this.fetchStatsOffers(), this.fetchFiltersOffers()])
			}

			return response
		},
		async deleteOffer(idOffer) {
			const { $repository } = useNuxtApp()
			const response = await $repository.v3.company.offers.deleteOffer(idOffer)

			if (response.success) {
				this.offers.data = this.offers.data.filter((offer) => offer.id !== idOffer)
				await Promise.allSettled([this.fetchStatsOffers(), this.fetchFiltersOffers()])
			}

			return response
		},
		async toggleOfferNotification(offerId, params) {
			const { $repository } = useNuxtApp()
			const response = await $repository.v4.hire.offers.postOfferNotification(offerId, params)

			if (response.success) {
				const offer = this.offers.data.find((offer) => offer.id === offerId)
				offer.notification_preferences_is_active = response.result.is_active
			}

			return response
		},
	},
})
