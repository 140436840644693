<template>
	<UModal
		v-model="model"
		:ui="{ width: 'sm:max-w-md' }"
		:title="$t('Borrar Oferta')"
		:ok-text="$t('Borrar')"
		:ok-loading="props.okLoading"
		:ok-handler="confirm"
	>
		<p class="text-sm">{{ $t('¿Está seguro de borrar la oferta?') }}</p>
	</UModal>
</template>

<script setup>
const model = defineModel()

const props = defineProps({
	okLoading: {
		type: Boolean,
		default: false,
	},
})

const emit = defineEmits(['confirm'])

const confirm = () => {
	emit('confirm')
}
</script>

<style></style>
