<template>
	<UModal
		v-model="model"
		hidden-footer
		hidden-header
	>
		<div class="space-y-2">
			<p class="text-primary font-semibold text-center text-xl">{{ $t('Gracias por tu feedback') }}</p>
			<p class="text-gray-600">
				{{
					$t(`Hola {name}, tu oferta se ha cerrado satisfactoriamente.`, {
						name: $auth.user.name || $auth.user.business.name,
					})
				}}
			</p>
			<p class="text-gray-600">
				{{ $t('Cuando desees puedes consultar tu oferta en la sección "ofertas inactivas".') }}
			</p>
			<div class="flex gap-3 justify-center pt-5">
				<UButton
					variant="outline"
					@click="close"
				>
					{{ $t('Cerrar') }}
				</UButton>
				<UButton
					to="/"
					@click="close"
				>
					{{ $t('Ir al Dashboard') }}
				</UButton>
			</div>
		</div>
	</UModal>
</template>

<script setup>
const model = defineModel()
const { $auth } = useNuxtApp()
const emit = defineEmits(['close'])

const close = async () => {
	emit('close')
}
</script>
