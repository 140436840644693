<template>
	<UModal
		v-model="model"
		:ui="{ width: 'sm:max-w-md' }"
		:title="$t('Vamos a finalizar tu oferta')"
		:cancel-text="$t('Cancelar')"
		:ok-text="$t('Confirmar y cerrar la oferta')"
		:ok-loading="props.okLoading"
		:ok-handler="confirm"
	>
		<div
			v-if="loadingReasons"
			class="text-center py-14 bg-white rounded-lg"
		>
			<UCircleLoader />
		</div>
		<div
			v-else
			class="flex flex-col gap-4"
		>
			<p class="text-sm">
				{{
					$t(
						'Gracias por haber usado Talently para publicar tu oferta laboral, agradecemos que nos puedas compartir el motivo del cierre.',
					)
				}}
			</p>
			<div>
				<URadio
					v-for="option in reasons"
					:key="option.id"
					v-model="selectedReason"
					:value="option.id"
					:label="option.label"
					:ui="{ label: 'mb-1 font-normal text-black' }"
					@change="
						() => {
							;(selectedCandidates = []), clearValidations()
						}
					"
				/>
				<span
					v-if="validationErrors.reason"
					class="text-red-500 text-sm"
				>
					{{ validationErrors.reason }}
				</span>
			</div>
			<div
				v-if="selectedReason == REASON_I_HAVE_HIRED"
				class="bg-gray-200 rounded-xl p-3"
			>
				<div
					v-if="hiredCandidates.length > 0"
					class="mb-2"
				>
					<span class="text-sm mr-2">{{ $t('Candidato(s) contratados:') }} </span>
					<UBadge
						v-for="hired in hiredCandidates"
						:key="hired.id"
						color="purple"
						variant="solid"
						class="mr-1"
						>{{ hired.name }}</UBadge
					>
				</div>
				<USelect
					v-if="!hasCompletedVacancies"
					v-model="selectedCandidates"
					:options="candidates"
					:message-danger="validationErrors.candidates ? validationErrors.candidates : null"
					:placeholder="$t('Elegir...')"
					:label="$t('¿Quién fue el talento?')"
					:close-on-select="false"
					multiple
					track-by="id"
					@select="
						() => {
							clearValidations()
						}
					"
				/>
			</div>
		</div>
	</UModal>
</template>

<script setup>
const { $repository } = useNuxtApp()
const { t: $t } = useI18n()
const model = defineModel()
const reasons = ref([])
const candidates = ref([])
const selectedReason = ref()
const selectedCandidates = ref([])
const hiredCandidates = ref([])
const loadingReasons = ref(false)
const REASON_I_HAVE_HIRED = ref(1)

const props = defineProps({
	offer: Object,
	okLoading: {
		type: Boolean,
		default: false,
	},
})

const validationErrors = ref({
	reason: null,
	candidates: null,
})

const emit = defineEmits(['confirm'])

const confirm = async () => {
	if (!validate()) return

	emit(
		'confirm',
		selectedReason.value,
		selectedCandidates.value.map((candidate) => candidate.id),
	)
}

const clearValidations = () => {
	validationErrors.value.reason = null
	validationErrors.value.candidates = null
}

const validate = () => {
	clearValidations()

	const reason = selectedReason.value
	const candidatesCount = selectedCandidates.value.length + hiredCandidates.value.length
	const vacanciesRequired = props.offer.vacancy_qty

	// Validar si se seleccionó un motivo
	if (!reason) {
		validationErrors.value.reason = $t('Por favor, selecciona un motivo para cerrar la oferta.')
		return false
	}

	// Validar vacantes asignadas
	if (reason === REASON_I_HAVE_HIRED.value && !hasCompletedVacancies.value && candidatesCount > vacanciesRequired) {
		validationErrors.value.candidates = $t(`No puedes seleccionar más de {vacanciesRequired} vacante(s).`, {
			vacanciesRequired,
		})
		return false
	}

	return true
}

const hasCompletedVacancies = computed(() => {
	return props.offer.vacancy_qty === hiredCandidates.value.length
})

onMounted(async () => {
	loadingReasons.value = true
	const response = await $repository.v4.company.offers.getPipelineReasonsClose(props.offer.id)

	if (response.success) {
		reasons.value = response.result.reasons
		candidates.value = response.result.data.filter((candidate) => !candidate.is_hired)
		hiredCandidates.value = response.result.data.filter((candidate) => candidate.is_hired)
	}

	loadingReasons.value = false
})
</script>

<style></style>
