<template>
	<UModal
		v-model="model"
		hidden-footer
		hidden-header
	>
		<div class="space-y-2">
			<p class="text-primary font-semibold text-center text-xl">{{ $t('Felicitaciones por tu contratación') }}</p>
			<p class="text-gray-600">
				{{
					$t(`Hola {name}, tu oferta se ha cerrado satisfactoriamente.`, {
						name: $auth.user.name || $auth.user.business.name,
					})
				}}
			</p>
			<p class="text-gray-600">
				{{ $t('Los candidatos seleccionados se han movido a la columna contratados de tu pipeline.') }}
			</p>
			<div class="flex gap-3 justify-center pt-5">
				<UButton
					to="/"
					variant="outline"
					@click="close"
				>
					{{ $t('Ir al Dashboard') }}
				</UButton>
				<UButton
					:to="`/offers/${props.offerId}/pipeline`"
					@click="close"
				>
					{{ $t('Ir a mi pipeline') }}
				</UButton>
			</div>
		</div>
	</UModal>
</template>

<script setup>
const model = defineModel()
const { $auth } = useNuxtApp()

const props = defineProps({
	offerId: Number,
})

const emit = defineEmits(['close'])

const close = async () => {
	emit('close')
}
</script>
